<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        What developing solvents are recommended for the thin-layer chromatography (TLC) procedure?
      </p>

      <p v-for="option in optionsAShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswersA" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>

      <p class="mb-2 mt-4">What are you going to use to identify the unknown compounds?</p>

      <p v-for="option in optionsBShuffled" :key="option.value" class="mb-0 mt-0">
        <v-checkbox v-model="inputs.studentAnswersB" :value="option.value" class="ml-6 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51LB_A8Q2C',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswersA: [],
        studentAnswersB: [],
      },
      optionsA: [
        {text: 'hexanes', value: 'hexanes'},
        {text: 'combination of hexanes and ethyl acetate', value: 'hexanesEthyl'},
        {
          text: 'combination of hexanes and ethyl acetate plus drop of exetic acid if streaking occurs',
          value: 'plusDrop',
        },
      ],
      optionsB: [
        {text: 'melting point and $R_f$ values', value: 'meltingPoint'},
        {text: 'infrared and NMR spectra', value: 'infrared'},
        {text: 'mass of the unknown after recrystallization', value: 'mass'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsAShuffled() {
      return seededShuffle(this.optionsA, this.seed);
    },
    optionsBShuffled() {
      return seededShuffle(this.optionsB, this.seed);
    },
  },
};
</script>
